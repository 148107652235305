define("discourse/plugins/slack/discourse/pre-initializers/extend-category-for-slack", ["exports", "ember-addons/ember-computed-decorators", "discourse/models/category"], function (_exports, _emberComputedDecorators, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: 'extend-category-for-slack',
    before: 'inject-discourse-objects',
    initialize() {
      var _dec, _obj, _init;
      _category.default.reopen((_dec = (0, _emberComputedDecorators.default)('custom_fields.slack_channel'), (_obj = {
        slack_channel: {
          get(channelField) {
            return channelField;
          },
          set(value) {
            this.set("custom_fields.slack_channel", value);
            return value;
          }
        }
      }, (_applyDecoratedDescriptor(_obj, "slack_channel", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "slack_channel"), _init = _init ? _init.value : undefined, {
        enumerable: true,
        configurable: true,
        writable: true,
        initializer: function () {
          return _init;
        }
      }), _obj)), _obj)));
    }
  };
});